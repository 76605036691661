import europe from '../../assets/images/contact/europe.jpg';
import india from '../../assets/images/contact/india.jpg';
import middleeast from '../../assets/images/contact/middleeast.jpg';
import northamerica from '../../assets/images/contact/northamerica.jpg';
import singapore from '../../assets/images/contact/singapore.jpg';
import gccKsa from '../../assets/images/contact/gcc-ksa.jpg';
import gccBahrain from '../../assets/images/contact/gcc-bahrain.jpg';
import gccKuwait from '../../assets/images/contact/gcc-kuwait.jpg';
import gccQatar from '../../assets/images/contact/gcc-qatar.jpg';
import gccOman from '../../assets/images/contact/gcc-oman.jpg';
import gccUae from '../../assets/images/contact/gcc-uae.jpg';
import morocco from '../../assets/images/contact/morocco.jpg';
import egypt from '../../assets/images/contact/egypt.jpg';
import poland from '../../assets/images/contact/poland.jpg';
import netherland from '../../assets/images/contact/netherland.jpg';
import china from '../../assets/images/contact/china-hk.jpg';
import vietnam from '../../assets/images/contact/vietnam.jpg';
import jordan from '../../assets/images/contact/jordan.jpg';



export const locations = [
    {
        id: 0,
        img: india,
        name: 'Delhi',
        region: 'asia',
        address: 'Suite 108-109, Hyatt Regency Delhi, Bhikaji Cama Place, Ring Road, New Delhi – 110066',
        mail: 'delhi@x-b4.com',
        phone: '+919971491205',
    },
    {
        id: 1,
        img: india,
        name: 'Mumbai',
        region: 'asia',
        address: '5th floor, Technopolis Knowledge Park, Mahakali Caves Rd, Chakala, Hanuman Nagar, Andheri East, Mumbai, Maharashtra 400093',
        mail: 'mumbai@x-b4.com',
        phone: '+91 9971491205',
    },
    {
        id: 2,
        img: europe,
        name: 'London',
        region: 'europe',
        address: '71-75 Shelton Street, London, Greater London, United Kingdom, WC2H 9JQ',
        mail: 'london@x-b4.com',
        phone: '+91 9971491205',
    },
    {
        id: 3,
        img: middleeast,
        name: 'Dubai',
        region: 'asia',
        address: 'Level 3, Building C3 , DWTC, Sheikh Zayed Road, P.O.Box 9573 – UAE',
        mail: 'dubai@x-b4.com',
        phone: '+966 58 265 1968',
    },
    {
        id: 5,
        img: singapore,
        name: 'Singapore',
        region: 'asia',
        address: 'Capital Square, Level 7, 23 Church Street, Singapore 049481',
        mail: 'singapore@x-b4.com',
        phone: '+91 9971491205',
    },
    {
        id: 6,
        img: gccKsa,
        name: 'GCC KSA',
        region: 'asia',
        address: `Al Khalidiya Al Janubiyah Dist. Abu Al Asaad Al Qusheri Street.
Building No.2687. Dammam`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 5,
        img: gccBahrain,
        name: 'GCC Bahrain',
        region: 'asia',
        address: `Office No. 13 1st floor.
Building No.1858. Road 1453, Block 814. Al Quds Road. Isa Town, Bahrain`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 5,
        img: gccKuwait,
        name: 'GCC KUWAIT',
        region: 'asia',
        address: `OFFICE 7107 Salem Mubarak Street
Alsalmiyah 395769. Kuwait`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 5,
        img: gccOman,
        name: 'GCC OMAN',
        region: 'asia',
        address: `Al-Masarrat building, East entrance, flat no. 201. Salalah, Oman.`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 5,
        img: gccQatar,
        name: 'GCC Qatar',
        region: 'asia',
        address: `Level 14, Commercial Bank Plaza, West Bay, Doha, Qatar`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 10,
        img: gccUae,
        name: 'gcc Uae',
        region: 'asia',
        address: `Saif Al Kaabi Building Office 34, Masfout, Ajman, UAE.`,
        mail: '',
        phone: '+966 58 265 1968',
    },
    {
        id: 11,
        img: morocco,
        name: 'morocco',
        region: 'asia',
        address: `Office Building, 
8ème étage #41-42, Tanger 90000, Marocco`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 12,
        img: egypt,
        name: 'egypt',
        region: 'asia',
        address: `35 Hassan Mamoun st, Nasr City, Cairo, Egypt`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 13,
        img: poland,
        name: 'poland',
        region: 'asia',
        address: `Targowa, No. 66, ap. 26, town. Warsaw, postal code 03-729,
Post Warsaw, Poland`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 14,
        img: netherland,
        name: 'netherlands',
        region: 'asia',
        address: `ARSANCO B.V. P/A ARSANCO B.V.
STADIONSTRAAT 11-C10. 4815 NC BREDA. Netherlands`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 15,
        img: china,
        name: 'china',
        region: 'asia',
        address: `Lucky Center
Flat/Rm 1506
NO 165-171 Wan Chai Road
Wan Chai - HK`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 16,
        img: vietnam,
        name: 'vietname',
        region: 'asia',
        address: `ECU Worldwide Vietnam
23 Street 8A Nam Long Residential Area, 
Tan Thuan Dong Ward, District 7, 
Ho Chi Minh City, Viet Nam.`,
        mail: '',
        phone: '+91 9971491205',
    },
    {
        id: 17,
        img: jordan,
        name: 'jordan',
        region: 'asia',
        address: `Wasfi Al-Tal Street, Building No. 150, Office No. 136
 Amman, Jordan`,
        mail: '',
        phone: '+91 9971491205',
    },
]
